import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import JotformEmbed from 'react-jotform-embed';
import styled from "styled-components";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import ImageCarousel from "../components/common/ImageCarousel"; // Adjust the import
import { graphql, Link } from "gatsby";

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`;

const ShrubsPage = ({ data }) => {
  const [carouselIsOpen, setCarouselIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openCarousel = (index) => {
    setCarouselIsOpen(true);
    setSelectedImageIndex(index);
  };

  const closeCarousel = () => {
    setCarouselIsOpen(false);
    setSelectedImageIndex(0);
  };

  const images = data.allFile.nodes.map((node) => node.childImageSharp.gatsbyImageData);

  return (
    <Layout>
      <Seo title="Plants" />

      <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
        <div className="w-full mx-auto pb-5 px-6 xl:px-20">
          <div className="relative">
            <GatsbyImage
              alt="Trees Hero Background"
              image={getImage(data.heroImage.childImageSharp.gatsbyImageData)}
              className="z-0"
            />
            <h1 className="main-title">Shrubs</h1>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-start mx-auto pb-16 px-6 xl:px-20 space-x-0 xl:space-x-14">
          <div className="w-full md:w-8/12 mx-auto">
            <StyledH2 className="text-site-green mb-3">Wide Selection of Beautiful Shrubs</StyledH2>

            <p className="font-body text-base mb-3">Our pride and joy! Our shrubs provide sensible, healthy, oxygen-producing beauty in an amazing choice of vivacious colors. Not only do they celebrate life and nature with their various and rich shades of greens, they also bring privacy to your clients’ residential or business locations with shapely ornamental hedges of almost any height.</p>

            <p className="font-body text-base">Our shrub inventory is vast and superb. We carry multiple sizes: one gallon, five gallon and fifteen gallon. We offer a great variety of drought-resistant, heat-tolerant, California natives, evergreens, variegated, nanas, and flowering selections. Take a moment to appreciate our eye-pleasing, hard-working shrubs many of which are offered in staked, ground covering, and trellis plant styles.</p>

            <Link to="/inventory">
              <button className="bg-white hover:bg-site-green-dark text-site-green-dark hover:text-white text-base font-medium text-center px-6 py-2 border border-site-green-dark mt-9">
                View Our Full Inventory
              </button>
            </Link>

            <div className="w-full bg-site-green-dark py-2 mt-5 mb-8">
              <h2 className="text-white text-lg font-semibold text-center uppercase">Photo Gallery</h2>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto">
              {images.map((image, index) => (
                <div key={index} onClick={() => openCarousel(index)}>
                  <GatsbyImage
                    alt={`Brothers Nursery Plant ${index + 1}`}
                    image={image}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="w-full md:w-4/12 mx-auto border mt-12 md:mt-2">
            <JotformEmbed src="https://form.jotform.com/233211525461042" />
          </div>
        </div>
      </div>

      {/* Conditionally render ImageCarousel */}
      {carouselIsOpen && (
        <ImageCarousel
          isOpen={carouselIsOpen}
          closeCarousel={closeCarousel}
          images={images}
          selectedImageIndex={selectedImageIndex}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "backgrounds/banner3-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1180
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allFile(filter: { relativePath: { regex: "/shrubs/shrubs-/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 800
            height: 800
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default ShrubsPage;